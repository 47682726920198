import { makeStyles } from "@material-ui/core";
import React from "react";
import NavBar from "../../components/NavBar";
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Grid, Box } from "@mui/material";
import divider from '../../assets/divider_01.png'

import {Swiper, SwiperSlide} from "swiper/react"

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation, Autoplay, Mousewheel, Keyboard } from "swiper/modules"

import image1 from '../../assets/homepage/equipamientoysensores.jpg'
import image2 from '../../assets/homepage/infraestructura.jpg'
import image3 from '../../assets/homepage/dron.jpg'
import image4 from '../../assets/homepage/montaje.jpg'
import image5 from '../../assets/homepage/obrasciviles.jpg'
import image6 from  '../../assets/homepage/panelessolares.jpg'
import image7 from '../../assets/homepage/sodar.jpg'
import image8 from '../../assets/homepage/mainstream.jpg'
import image9 from '../../assets/homepage/res.jpg'
import image10 from '../../assets/homepage/nucapital.jpg'
import image11 from '../../assets/homepage/neoget.jpg'
import image12 from '../../assets/homepage/windhunter.jpg'
import image13 from '../../assets/homepage/wknusa.JPG'
import image14 from '../../assets/homepage/acciona.JPG'


const useStyles = makeStyles(theme=>({
  
    text:{
        textAlign : 'center',
        marginTop: theme.spacing(15),
        
    },
    text2:{
        textAlign : 'center',
        marginTop: theme.spacing(3),
        padding: '10px 20px',
        color: 'white'
    },
    imgn:{
        align: 'center',
    },
    text3:{
        textAlign: 'center',
        marginBottom: theme.spacing(15)
    },

    imgn2:{
        width: "360px"
    }

    
    
  }))

const images = [
    {
        id:0, title:'Equipamiento, sensores y accesorios', path: image1, link: 'equipamiento'
    },
    {
        id:1, title:'Infraestructura', path: image2, link:'infraestructura'
    },
    {
        id:2, title:'Servicios de inspeccion', path: image3, link: 'inspeccion'
    },
    {
        id:3, title:'Montaje', path: image4, link:'montaje'
    },
    {
        id:4, title:'Obras Civiles', path: image5, link: 'obrasciviles'
    },
    {
        id:5, title:'Equipamiento, Proyectos Solares, Estaciones Fijas y Seguimiento', path: image6, link: 'equipamientoProyectosSolaresEstacionesFijasYseguimiento' 
    },
    {
        id:6, title:'Equipamiento, Deteccion Remota, LIDAR & SODAR', path: image7, link: 'equipamientoDeteccionRemotaLidarSodar' 
    }

// Agrega más nombres de imágenes aquí
];

const clientes = [
    {
        id:7 , path: image14
    },
    {
        id:8 , path: image8
    },
    {
        id:9 , path: image9
    },
    {
        id:10 , path: image10
    },
    {
        id:11 , path: image11
    },
    {
        id:12, path: image12
    },
    {
        id:13, path: image13
    },


];

const imageLinks = [
    '#equipamientoysensores',  // Ruta correspondiente a imagen1.jpg
    '#infraestructura',  // Ruta correspondiente a imagen2.jpg
    '#inspeccion',
    '#montaje',
    '#obrasciviles',
    '#panelessolares',
    '#sodar' 
    
    // Agrega más rutas aquí
];



export default function Home() {
    const classes = useStyles()
    const history = useNavigate();
    const handleImageClick = (imageId) => {
        // Redirige a la página de detalles de la imagen
        history(`/${imageId}`);
    };
    return (
        <div> 
            
            <Container sx = {{marginTop:8}} maxWidth = 'xl'>       
                <NavBar />
                <h1 className = { classes.text }>
                    <Typography variant="h4" align="center" paragraph sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}  >
                        Infraestructura, equipamiento y servicios para ERNC 
                    </Typography>

                    <Typography variant="h4" align="center" paragraph sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}>
                        Nuestra especialidad es el diseño e implementación 
                    </Typography>

                    <Typography variant="h4" align="center" paragraph sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}>
                        de campañas para evaluación de potencial 
                    </Typography>

                    <Typography variant="h4" align="center" paragraph sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}>
                        Eólico y Solar 
                    </Typography>

                    <Typography variant="h4" align="center" paragraph sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}} marginBottom={4}>
                        con fines de generacion eléctrica.
                    </Typography>
                    
                </h1>
                
                <div align = 'center' >
                    <img src = {divider} />
                </div>
                <p></p>
                
            </Container>
            <p></p>

            <div className="container">
                    <Swiper
                        effect = {'fade'}
                        grabCursor = {true}
                        slidesPerView={'auto'}
                        centeredSlides = {true}
                        align = 'center'
                        
                        
                        cssMode = {true}
                        mousewheel = {true}
                        keyboard = {true}
                        coverflowEffect={
                            {
                                rotate: 0,
                                stretch: 0,
                                depth: 100,
                                modifier: 2.5
                            }
                        }
                        autoplay = {{
                            delay: 3000,
                            disableOnInteraction: false
                        }}
                        pagination = {true}
                        navigation = {true}
                        modules={[ Pagination, Navigation, Autoplay, Mousewheel, Keyboard]}
                        className="swiper_container"
                    >
                        
                       
                        {images.map((image,index)=>(
                            
                            
                            <SwiperSlide >
                                
                                <div className="image-container" onClick={() => handleImageClick(image.link)}>
                                    <img src={image.path} alt={`Slide ${index}`}  className = { classes.imgn }/>
                                    <div className="image-overlay">
                                        <Typography className = { classes.text2 } variant="h4" sx = {{fontWeight: 'bold'}} >{image.title}</Typography>
                                    </div>
                                </div> 
                            
                            </SwiperSlide>
                            
                            
                            ))}
                       
                      
             
    
                    </Swiper> 

                    <Grid container >
                        <Grid item xs = {12} sm = {6} >
                            <Typography className = { classes.text3 }variant="h5" sx = {{fontWeight: 'bold',fontFamily: 'Open Sans, sans-serif'}} mt={2}>Experto</Typography>
                            <br></br>
                            <Typography variant="body2" className = { classes.text3 } mb = {7} mr={10} ml={10} align="justify" paragraph = {true} sx={{ fontFamily: 'Open Sans, sans-serif' }}> 
                                Desde 1988 hemos recorrido el camino que nos ha permitido construir una trayectoria avalada en numerosos proyectos desarrollados en Chile y el extranjero.
                            </Typography>
                        </Grid>
                        <Grid item xs = {12} sm = {6} >
                            <Typography className = { classes.text3 }variant="h5" sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}} mt={2}>Confiable</Typography>
                            <br></br>
                            <Typography variant="body2" className = { classes.text3 } mb = {7} mr={10} ml={10} align="justify" paragraph = {true} sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                                Nuestra propuesta está basada en un compromiso personal y acciones concreta, para asegurar la correcta implementación en un tiempo definido "On Time" y en el lugar indicado "On Site".
                            </Typography>
                        </Grid>
                    </Grid>

                            

                    <Typography className = { classes.text3 }variant="h4" sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}} mb={4}>Clientes Destacados</Typography>
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={5}
                        grabCursor = {false}
                        
                        centeredSlides={false}
                        autoplay = {{
                            delay: 2000,
                            disableOnInteraction: false
                        }}
                        pagination = {false}
                        navigation = {false}
                        modules={[Autoplay, Navigation]}
                    >
                        {clientes.map((image, index)=>(
                            <div className="image-container2">
                                <SwiperSlide>
                                    <img src={image.path} alt={`Slide ${index}`} style={{width:'200px',height:'100px'}}/>
                                </SwiperSlide>
                            </div>
                        ))}

                        
                    </Swiper>

            </div>
            <div align = 'center' >
                <img src = {divider} />
            </div>

        </div>
    );
}       

/**
 * <Box display="flex" alignItems="center" justifyContent="center">
                    <IconButton onClick={handlePrev}>
                        <ChevronLeft/>
                    </IconButton>
                    <div className="image-container">
                        {images.map((image,index)=>(
                            <div
                                key={index}
                                className={`image ${index === currentIndex ? 'active' : ''}`}
                                onClick={() => handleImageClick(index)}
                            >
                                <img src={image.path} alt={`Slide ${index}`}/>
                                <div className="overlay">
                                    {image.title}
                                </div>
                            </div>
                        ))}
                    </div>
                    <IconButton onClick={handleNext}>
                        <ChevronRight/>
                    </IconButton>
                </Box>


        <SwiperSlide >
            <img src = {image1} alt = 'slide_image'/>
        </SwiperSlide>
        <SwiperSlide>
            <img src = {image2} alt = 'slide_image'/>
        </SwiperSlide>
        <SwiperSlide>
            <img src = {image3} alt = 'slide_image'/>
        </SwiperSlide>
        <SwiperSlide>
            <img src = {image4} alt = 'slide_image'/>
        </SwiperSlide>
        <SwiperSlide>
            <img src = {image5} alt = 'slide_image'/>
        </SwiperSlide>
        <SwiperSlide>
            <img src = {image6} alt = 'slide_image'/>
        </SwiperSlide>
        <SwiperSlide>
            <img src = {image7} alt = 'slide_image'/>
    </SwiperSlide>
 */

/**                                <Box sx={{marginBottom:10}}>
                                    <SwiperSlide >
                                        <Typography className = { classes.text2 } variant="h4" sx = {{fontWeight: 'bold'}} >{image.title}</Typography>
                                        <img src={image.path} alt={`Slide ${index}`} onClick={() => handleImageClick(image.link)} className = { classes.imgn }/> 
                                       
                                        </SwiperSlide>
                                        </Box>
                                             */