import './App.css';
import {
  Home, 
  Suministros, 
  Servicios, 
  Experiencia, 
  Novedades, 
  Acercade, 
  Contacto, 
  Equipamiento, 
  Infraestructura, 
  Montaje, 
  ObrasCiviles, 
  Inspeccion, 
  Sodar, 
  ProyectosSolares, 
  AgregarClientes, 
  AgregarProyecto,
  Login,
  Proyectos,
  DetalleProyecto,
  Clientes,

  ImgProyectos,
  DetalleExperiencia
} from './pages'
import { Routes, Route } from 'react-router-dom'
import { Container, } from "@mui/material";
import {PrivateRoute} from './components'
import { AuthProvider } from './context'

function App() {
  return (
    <div>

      <AuthProvider>
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/suministros" element={<Suministros/>}/>
            <Route path="/servicios" element={<Servicios/>}/>
            <Route path="/experiencia" element={<Experiencia/>}/>
            <Route path="/novedades" element={<Novedades/>}/>
            <Route path="/acercade" element={<Acercade/>}/>
            <Route path="/contacto" element={<Contacto/>}/>
            <Route path="/equipamiento" element={<Equipamiento/>}/>
            <Route path="/infraestructura" element={<Infraestructura/>}/>
            <Route path="/montaje" element={<Montaje/>}/>
            <Route path="/obrasciviles" element={<ObrasCiviles/>}/>
            <Route path="/equipamientoDeteccionRemotaLidarSodar" element={<Sodar/>}/>
            <Route path="/inspeccion" element={<Inspeccion/>}/>
            <Route path="/equipamientoProyectosSolaresEstacionesFijasYseguimiento" element={<ProyectosSolares/>}/>
            <Route path="/getdetalleexperiencia/:projectId" element={<DetalleExperiencia/>}/>
            <Route path='login' element={<Login/>}/>

            <Route element={<PrivateRoute/>}>
              
              <Route path='/proyectos/agregarProyecto' element ={<AgregarProyecto/>}/>
              <Route path='/proyectos' element={<Proyectos/>}/>
              <Route path='proyectos/getproyectos/:projectId' element={<DetalleProyecto/>}/>
              <Route path='/clientes' element={<Clientes/>}/>
              <Route path='/clientes/agregarCliente' element={<AgregarClientes/>}/>
              <Route path='proyectos/imagenesproyecto/:projectId' element={<ImgProyectos/>}/>
            </Route>
        </Routes>
      </AuthProvider>
      
      
      
    </div>
  );
}

export default App;
