import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import NavBar from "../../components/NavBar";
import { Container, Typography, Button} from "@mui/material";
import divider from '../../assets/divider_01.png' 
import {Swiper, SwiperSlide} from "swiper/react"
import 'swiper/css';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import image1 from '../../assets/sodarpage/11_E3P5_slide_img_01.jpg'
import image2 from '../../assets/sodarpage/11_E3P5_slide_img_02.jpg'
import image3 from '../../assets/sodarpage/11_E3P5_slide_img_03.jpg'
import image4 from '../../assets/sodarpage/11_E3P5_slide_img_04.jpg'
import image5 from '../../assets/sodarpage/11_E3P5_slide_img_05.jpg'



const useStyles = makeStyles(theme=>({
    root:{
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100v'

        
    },
    container:{
        opacity: '0.8',
        height: '80%', //largo del contenedor
        marginTop: theme.spacing(10), //altura del contenedor
        [theme.breakpoints.down(400 + theme.spacing(4)+4)]:{ //responsive
            marginTop: 0,
            width: '100%',
            height: '100%'
        }
    },
    div:{
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'

    },
    avatar:{
        margin: theme.spacing(6),
        marginBottom: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
       
    },
    form:{
        width: '100%',
        marginTop: theme.spacing(1)
    },
    button:{
        margin: theme.spacing(3, 0, 2) //mrgen general
        
    },

    text:{
        textAlign : 'center',
        marginTop: theme.spacing(15),
        
    },
    text2:{
        marginLeft: 128,
        marginTop: 25
    },
    text3:{
        textAlign : 'center',
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(6)
    },
    modal:{
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2,4,3),
        top: '50%',
        left:'50%',
        transform: 'translate(-50%, -50%)'
    },
    icons: {
        cursor: 'pointer'
    },
    inputMaterial:{
        width: '100%'
    },  

    swiper:{
        width: "100%",
        height: "100%",
        padding: 0
    },
      
    swiperSlide: {
        textAlign: "center",
        fontSize: "18px",
        background: "#fff",
        padding: 0,
        lineHeight: 0 ,
        /* Center slide text vertically */
        display: "flex",
        justifContent: "center",
        alignItems: "center",
    },
      
    swiperSlidImg: {
        display: "flex",
        width: "100%",
        height: "100%",
        
    }

}))

const images = [
    {
        id:0, path:image1
    },
    {
        id:1, path:image2
    },
    {
        id:2, path:image3
    },
    {
        id:3, path:image4
    },
    {
        id:4, path:image5
    },
    
]
export default function Sodar(){
    const classes = useStyles()
    const imageFile = new Image();
    
    return(
        <div>
            <Container maxWidth = 'xl' sx = {{marginTop:8}}>
                <NavBar/>
            </Container>
            <Container sx = {{marginTop:8}} maxWidth = 'lg'>
                <Typography variant="h4" align="left" sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}  mt={15}  >
                    Unidad Sodar 
                </Typography >   
                <br></br>
                <br></br>
                <Grid container>
                    <Grid xs = {12} sm = {10} xl={8}>
                        <Typography  variant="h5"  sx = {{ fontFamily: 'Open Sans, sans-serif'}} color={'grey'} mb={6}>
                            Bearable only through love hydrogen atoms bits of moving fluff culture shores of the cosmic ocean roxysm 
                            of global death rich in heavy atoms with miles away from sun.
                        </Typography>
                    </Grid>
                    
                    <Grid xs = {12} sm = {10} xl={8}>
                        <Typography variant="h6"  sx = {{ fontFamily: 'Open Sans, sans-serif'}} > 
                            Bearable only through love hydrogen atoms bits of moving fluff culture shores of the cosmic ocean paroxysm of 
                            global death rich in heavy atoms with pretty stories for which there’s little good evidence something incredible 
                            is waiting to be known not a sunrise but a galaxyrise shores of the cosmic ocean inconspicuous motes of rock.
                        </Typography>
                    </Grid>
                </Grid>
                 
            </Container>
            <br></br>
            <Swiper
                
                //effect = {'fade'}
               //grabCursor = {true}
                spaceBetween={0}
                slidesPerView = {1}
                className={classes.swiper}
                style={{ margin: 0, padding: 0 }}
                //align = 'center'
                //cssMode = {true}
                //keyboard = {true}
                //pagination = {true}
                //navigation = {true}
                //modules={[  Navigation, Autoplay]}
            >
                {images.map((image, index)=>(
                    <SwiperSlide key={image.id} className={classes.swiperSlide}>
                        
                        <img src={image.path} alt={`Slide ${index}`} style={{margin: '0px', width: '50%', height: 'auto', borderRadius: '0px',border: 'none', margin:"0", padding:"0" }} className={classes.swiperSlidImg}/>
                        
                        
                    </SwiperSlide>
                ))}
            </Swiper>
            <br></br>
            <br></br>
            <br></br>
            <Container maxWidth='lg'>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12} sm={6} align="center">
                        <Button startIcon={<ArrowBackIcon style={{ fontSize: "3rem" }} />} component='a' href={'/equipamientoProyectosSolaresEstacionesFijasYseguimiento'} style={{color:'black'}}>
                            <Typography variant="h5" sx={{ fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif', textAlign: 'center', color:'black'}}>
                                anterior
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} align="center">
                        <Button endIcon={<ArrowForwardIcon style={{ fontSize: "3rem" }} />} component='a' href={'/'} style={{color:'black'}}>
                            <Typography variant="h5" sx={{ fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif', textAlign: 'center', color:'black' }}>
                                Home
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Container>
            <br></br>
            <br></br>
            <br></br>
            <div align = 'center' >
                <img src = {divider} />
            </div>
        </div>
    );
}

/** */