import React, { useState, useEffect } from 'react';
import axios, { AxiosError } from 'axios';
import { Grid, makeStyles } from "@material-ui/core";
import NavBar from "../../components/NavBar";
import { useParams } from 'react-router-dom';
import { Container, Typography } from "@mui/material";
import {Swiper, SwiperSlide} from "swiper/react"
import { Pagination, Navigation, Autoplay, Mousewheel, Keyboard } from "swiper/modules"
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';


const useStyles = makeStyles(theme=>({
    root:{
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100v'

        
    },
    container:{
        opacity: '0.8',
        height: '80%', //largo del contenedor
        marginTop: theme.spacing(10), //altura del contenedor
        [theme.breakpoints.down(400 + theme.spacing(4)+4)]:{ //responsive
            marginTop: 0,
            width: '100%',
            height: '100%'
        }
    },
    div:{
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'

    },
    avatar:{
        margin: theme.spacing(6),
        marginBottom: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
       
    },
    form:{
        width: '100%',
        marginTop: theme.spacing(1)
    },
    button:{
        margin: theme.spacing(3, 0, 2) //mrgen general
        
    },

    text:{
        marginLeft: 155,
        marginTop: 30
    },
    text2:{
        marginLeft: 128,
        marginTop: 25
    },
    text3:{
        textAlign : 'center',
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(6)
    },
    customFont: {
        fontFamily: "Arial, sans-serif",
    },
    
    
}))

const regiones = {
    AP: 'Región de Arica y Parinacota',
    TA: 'Región de Tarapacá',
    AN: 'Región de Antofagasta',
    AT: 'Región de Atacama',
    CO: 'Región de Coquimbo',
    VA: 'Región de Valparaíso',
    RM: 'Región Metropolitana de Santiago',
    LI: "Región del Libertador General Bernardo O'Higgins",
    ML: 'Región del Maule',
    NB: 'Región de Ñuble',
    BI: 'Región del Biobío',
    AR: 'Región de La Araucanía',
    LR: 'Región de Los Ríos',
    LL: 'Región de Los Lagos',
    AI: 'Región de Aysén del General Carlos Ibáñez del Campo',
    MA: 'Región de Magallanes y de la Antártica Chilena'
}
const obtenerNombreRegion = (abreviatura) => {
    return regiones[abreviatura] || abreviatura;
};

export default function DetalleExperiencia(){
    const classes = useStyles()
    const { projectId } = useParams();
    let [data, setData] = useState(null);
    const[proyectoSeleccionado, setProyectoSeleccionado] = useState({})
    
    const [imgData, setDataImage] = useState([])

    const getProjectDetails = async()=>{
        
        await axios.get('https://desamd-api.onrender.com/api/proyectos/getproyectosID/'+projectId)
        .then(response=>{
            setData(response.data)
            setProyectoSeleccionado(response.data?response.data[0]:'')
        })
    }
    useEffect (() =>{
        getProjectDetails();

    },[])

    useEffect(() => {
        const fetchImages = async () => {
          try {
            const response = await axios.get(`https://desamd-api.onrender.com/api/proyectos/getimagenes/${projectId}`);
            const imageList = response.data;
            setDataImage(imageList); // Actualiza el estado con la lista de imágenes
          } catch (error) {
            console.error(error);
          }
        };
    
        fetchImages(); // Llama a la función para obtener imágenes cuando el componente se monta
    }, [projectId]); // Asegúrate de que la función se llame cuando projectId cambie
    console.log(imgData)
    
    return(
        <div>
            <Container sx = {{marginTop:8}} maxWidth = 'xl'>
                <NavBar/>
            </Container>
            <Container sx = {{marginTop:8}} maxWidth = 'lg'>
                <ImageList sx={{ alignItems: 'center', marginTop:'40px' }} cols={2} rowHeight={400}>
                    {(imgData).map((image, index) =>(
                        <ImageListItem>
                            <img 
                                srcSet={image.dataUrl}
                                src={image.dataUrl}
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
                <br></br>
                <Grid container style={{ marginTop: '40px' }}>
                    <Grid item xs={12} sm={5} style={{ marginTop: '60px' }}>
                        <Typography variant='h6' color={'grey'}  sx={{ fontFamily: 'Open Sans, sans-serif' }} >
                            PROYECTO 
                        </Typography>
                        <Typography variant='body1' color={'grey'}  sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                            {proyectoSeleccionado.id_proyecto}
                        </Typography>
                        <br></br>
                        <Typography variant='h6' color={'grey'}  sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                            FECHA
                        </Typography>
                        <Typography variant='body1' color={'grey'}  sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                        {proyectoSeleccionado && proyectoSeleccionado.fecha_fi
                        ? proyectoSeleccionado.fecha_fi.toString().slice(0, 10)
                        : "Fecha no disponible"}
                        </Typography>
                        <br></br>
                        <Typography variant='h6' color={'grey'} sx={{ fontFamily: 'Open Sans, sans-serif' }}> 
                            ZONA
                        </Typography>
                        <Typography variant='body1' color={'grey'} sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                            {obtenerNombreRegion(proyectoSeleccionado.region)}

                        </Typography>
                    
                    </Grid>
                    <Grid item xs={12} sm={7} style={{ marginTop: '40px' }} >
                        <Typography variant='h2' align='left' sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                            {proyectoSeleccionado.titulo_proyecto}
                        </Typography>
                        <br></br>
                        <Typography variant='h4' align='left' color={'grey'}  sx={{ fontFamily: 'Open Sans, sans-serif' }}> 
                            {proyectoSeleccionado.subtitulo_proyecto}
                        </Typography>
                        <br></br>
                        <Typography variant='body1' align='left' paragraph = {true} sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                            {proyectoSeleccionado.descripcion}
                        </Typography>
                    </Grid>

                </Grid>
            </Container>
            <br></br>
            <br></br>
        </div>
    );
}

/**<div className='container'>
                    <Swiper
                        effect = {'fade'}
                        grabCursor = {true}
                        slidesPerView={'auto'}
                        centeredSlides = {true}
                        align = 'center'
                        cssMode = {true}
                        mousewheel = {true}
                        keyboard = {true}
                        coverflowEffect={
                            {
                                rotate: 0,
                                stretch: 0,
                                depth: 100,
                                modifier: 2.5
                            }
                        }
                        autoplay = {{
                            delay: 3000,
                            disableOnInteraction: false
                        }}
                        pagination = {true}
                        navigation = {true}
                        modules={[ Pagination, Navigation, Autoplay, Mousewheel, Keyboard]}
                        className="swiper_container"
                    >
                        {(imgData).map((image, index)=>(
                            <SwiperSlide>
                                <img src={image.dataUrl} className = { classes.imgn }/>
                            </SwiperSlide>
                        ))}
                    </Swiper>

                </div> */