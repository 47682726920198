import React from "react";
import { makeStyles } from "@material-ui/core";
import NavBar from "../../components/NavBar";
import { Container, Typography } from "@mui/material";

const useStyles = makeStyles(theme=>({
  
    text:{
        textAlign : 'center',
        marginTop: theme.spacing(15),
        
    },
    
    
  }))

export default function Montaje(){
    const classes = useStyles()
    return(
        <div>
            <Container sx = {{marginTop:8}} maxWidth = 'xl'>
                <NavBar/>
                 <h1 className = { classes.text }>  Montaje page in progresss... </h1>
            </Container>
            
        </div>
    );
}