import {    
    Toolbar,
    Typography,   
    IconButton,
    Button,
    Drawer,
} from '@mui/material';
import { useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import NavListDrawerAdm from "../NavListDrawerAdm";
import { Box } from '@mui/system'
import logo from '../../assets/logo_2x-removebg-preview.png'
import HomeIcon from '@mui/icons-material/Home';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import InfoIcon from '@mui/icons-material/Info';
import InboxIcon from '@mui/icons-material/Inbox';
import CellTowerIcon from '@mui/icons-material/CellTower';
import * as React from "react";
import { useAuth } from '../../context/AuthContext';



const navLink = [
    {
        title:'Proyectos', path: '/proyectos', icon: <HomeIcon/>
    },
    {
        title:'Clientes', path: '/clientes', icon: <CellTowerIcon/>
    },
    
]

export default function NavBarAdm() {
    const [error, setError] = useState ('');
    const[open, setOpen] = useState(false)
    const { logout } = useAuth(); //esta funcion viene de /context/AuthContext

    const handleLogout = async () => {
        try{
            await logout();
        } catch (error){ 
            setError('Server Error')
        }
    }
    return (
        <>
            
                <Toolbar >
                    <IconButton
                        onClick={() => setOpen(true)}
                        size = 'large'
                        sx = {{display: {xs:"block", sm:"none"}}}
                    >
                        <MenuIcon />
                    </IconButton>
                    <img src= { logo } />
                    <Typography sx = {{ flexGrow:1 }}></Typography>

                    <Box sx = {{display: {xs:"none", sm:"block"}, marginTop:4}}>
                        {
                            navLink.map(item =>(
                                <Button
                                    color = 'inherit'
                                    key={item.title}
                                    component = 'a'
                                    href = {item.path}
                                    sx={{ fontFamily: 'Open Sans, sans-serif' }}
                                >
                                    {item.title}
                                </Button>
                            ))
                        }
                        
                        <Button
                            variant = 'contained'
                            
                            onClick = {handleLogout}
                            sx={{marginLeft: 2}}
                        >
                            Salir
                        </Button>
                    </Box>

                </Toolbar>
            

            <Drawer 
            open = {open}
            anchor="left"
            onClose={() => setOpen(false)}
            >
                <NavListDrawerAdm navLink = {navLink}/>
            </Drawer>
           
        </>
    );
}       