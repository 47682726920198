import {    
    Toolbar,
    Typography,   
    IconButton,
    Button,
    Drawer,
} from '@mui/material';
import { useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import NavListDrawer from "../NavListDrawer";
import { Box } from '@mui/system'
import logo from '../../assets/logo_2x-removebg-preview.png'
import HomeIcon from '@mui/icons-material/Home';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import InfoIcon from '@mui/icons-material/Info';
import InboxIcon from '@mui/icons-material/Inbox';
import CellTowerIcon from '@mui/icons-material/CellTower';
import * as React from "react";
import { Link } from 'react-router-dom';




const navLink = [
    {
        title:'Inicio', path: '/', icon: <HomeIcon/>
    },
    {
        title:'Suministros', path: '/suministros', icon: <CellTowerIcon/>
    },
    {
        title:'Servicios', path: '/servicios', icon: <MiscellaneousServicesIcon/>
    },
    {
        title:'Exeriencia', path: '/experiencia', icon: <WorkHistoryIcon/>
    },
    {
        title:'Novedades', path: '/novedades', icon: <NewspaperIcon/>
    },
    {
        title:'Acerca de', path: '/acercade', icon: <InfoIcon/>
    },
    {
        title:'Contacto', path: '/contacto', icon: <InboxIcon/>
    },
]

export default function NavBar() {
    
    const[open, setOpen] = useState(false)
    return (
        <>
            
            <Toolbar >
                <IconButton
                    onClick={() => setOpen(true)}
                    size = 'large'
                    sx = {{display: {xs:"block", sm:"none"}}}
                >
                    <MenuIcon />
                </IconButton>
                <Link to={'/'}>
                    <img src= { logo } />
                </Link>
                <Typography sx = {{ flexGrow:1 }}></Typography>

                <Box sx = {{display: {xs:"none", sm:"block"}, marginTop:4}}>
                    {
                    navLink.map(item =>(
                            <Button
                                color = 'inherit'
                                key={item.title}
                                component = 'a'
                                href = {item.path} 
                                sx={{ fontFamily: 'Open Sans, sans-serif' }}
                            >
                                {item.title}
                            </Button>
                    ))
                    }
                </Box>
            </Toolbar>
        

            <Drawer 
            open = {open}
            anchor="left"
            onClose={() => setOpen(false)}
            >
                <NavListDrawer navLink = {navLink}/>
            </Drawer>
        </>
    );
}       