import React , {useState, useEffect} from "react";
import { Box, Button, Grid, TextField, makeStyles } from "@material-ui/core";
import NavBarAdm from "../../components/NavBarAdm";
import { useNavigate } from 'react-router-dom';
import { Container, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import axios from 'axios'
import {Swiper, SwiperSlide} from "swiper/react"
import { Pagination, Navigation, Autoplay, Mousewheel, Keyboard } from "swiper/modules"


const useStyles = makeStyles(theme=>({
  
    root:{
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100v'

        
    },
    container:{
        opacity: '0.8',
        height: '80%', //largo del contenedor
        marginTop: theme.spacing(10), //altura del contenedor
        [theme.breakpoints.down(400 + theme.spacing(4)+4)]:{ //responsive
            marginTop: 0,
            width: '100%',
            height: '100%'
        }
    },
    div:{
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'

    },
    avatar:{
        margin: theme.spacing(6),
        marginBottom: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
       
    },
    form:{
        width: '100%',
        marginTop: theme.spacing(1)
    },
    button:{
        margin: theme.spacing(3, 0, 2) //mrgen general
        
    },

    text:{
        marginLeft: 155,
        marginTop: 30
    },
    text2:{
        marginLeft: 128,
        marginTop: 25
    },
    text3:{
        textAlign : 'center',
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(6)
    }
    
    
  }))

export default function ImgProyectos(){
    const classes = useStyles()
    const history = useNavigate();
    const [selectedImage, setSelectedImage] = useState(null);
    const [imgData, setDataImage] = useState([])
    const { projectId } = useParams()
    
    console.log(projectId)
    //falta crear el getImage en el back y llamarlo desde aca.
   
    // Maneja el cambio de la imagen seleccionada
    const handleImageChange = (event) => {
      const file = event.target.files[0];
      setSelectedImage(file);
    };

    // const getImageProject = async()=>{
    //     await axios.get('http://localhost:4000/api/proyectos/getimagenes/'+projectId)
    //     .then(response=>{
    //         setDataImage(response.data)
    //         console.log(response.status)
    //     }).catch(console.error())
    // }
    // useEffect (() =>{
    //     getImageProject();

    // },[])

    useEffect(() => {
        const fetchImages = async () => {
          try {
            const response = await axios.get(`https://desamd-api.onrender.com/api/proyectos/getimagenes/${projectId}`);
            const imageList = response.data;
            setDataImage(imageList); // Actualiza el estado con la lista de imágenes
          } catch (error) {
            console.error(error);
          }
        };
    
        fetchImages(); // Llama a la función para obtener imágenes cuando el componente se monta
    }, [projectId]); // Asegúrate de que la función se llame cuando projectId cambie
    
    console.log(imgData)
    const sendHandler = () =>{
        if(!selectedImage){
            alert('Debes selecconar una imagen')
            return
        }

        const formdata = new FormData()
        formdata.append('image', selectedImage)

        fetch('https://desamd-api.onrender.com/api/proyectos/addimagenes/' + projectId,{
            method: 'POST',
            body: formdata
        })
        .then(res => res.text())//recibe txt desde el srv
        .then(res => console.log(res)) //agregar sweet alert
        .catch(err => {
            console.error(err)
        })
        
        document.getElementById('fileinput').value = null
        setSelectedImage(null)
    }

    const handleProjectDetails = (projectId) => {
        // Cambia la ruta a la página de detalles del proyecto
        history(`/proyectos/getproyectos/${projectId}`);
    };

    return(
        <div>
            <Container sx = {{marginTop:8, marginBottom:4}} maxWidth = 'xl'>
                <NavBarAdm/>
                <h1 className = { classes.text3 }>
                    <Typography variant="h3" align="center" sx = {{fontWeight: 'bold'}} mb={6}>
                        Imagenes proyecto
                    </Typography>   
                </h1>
              
                
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs={12} sm={6} md={4}>
                    
                        <TextField
                            type="file"
                            id="fileinput"
                            onChange={handleImageChange}
                            variant="outlined"
                            size="small"
                            fullWidth
                            inputProps={{
                                accept: 'image/*', // Limita la selección a archivos de imagen
                            }}
                        />
                        
                    </Grid>
                    <Grid item  xs={12} sm={2} md={2}>
                        <Button variant="contained" size="medium"fullWidth onClick={sendHandler}>Subir</Button>
                    </Grid>
                    
                </Grid>
                
                <div className="container">
                    <Swiper
                        effect = {'fade'}
                        grabCursor = {true}
                        slidesPerView={'auto'}
                        centeredSlides = {true}
                        align = 'center'
                        cssMode = {true}
                        mousewheel = {true}
                        keyboard = {true}
                        coverflowEffect={
                            {
                                rotate: 0,
                                stretch: 0,
                                depth: 100,
                                modifier: 2.5
                            }
                        }
                        autoplay = {{
                            delay: 3000,
                            disableOnInteraction: false
                        }}
                        pagination = {true}
                        navigation = {true}
                        modules={[ Pagination, Navigation, Autoplay, Mousewheel, Keyboard]}
                        className="swiper_container"
                    >
                        {(imgData).map((image, index)=>(
                            <SwiperSlide>
                                <img src={image.dataUrl} className = { classes.imgn }/>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        className={classes.button}
                        type="button"
                        size="large"
                        variant="contained"
                        color='primary'
                        onClick={() => handleProjectDetails(projectId)}
                    >
                        Atras
                    </Button>
                </div>
            </Container>
        </div>
    );
}