import React, { useState, useEffect } from 'react';
import axios, { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { Tab, TextField, makeStyles,} from "@material-ui/core";
import Box from '@mui/material/Box';
import NavBarAdm from "../../components/NavBarAdm";
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import Cookies from 'js-cookie';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  Typography,
  Button,
  Modal,
  useMediaQuery, 
  useTheme 
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import TablePagination from '@mui/material/TablePagination';


const useStyles = makeStyles(theme=>({
    root:{
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100v'

        
    },
    container:{
        opacity: '0.8',
        height: '80%', //largo del contenedor
        marginTop: theme.spacing(10), //altura del contenedor
        [theme.breakpoints.down(400 + theme.spacing(4)+4)]:{ //responsive
            marginTop: 0,
            width: '100%',
            height: '100%'
        }
    },
    div:{
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'

    },
    avatar:{
        margin: theme.spacing(6),
        marginBottom: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
       
    },
    form:{
        width: '100%',
        marginTop: theme.spacing(1)
    },
    button:{
        margin: theme.spacing(3, 0, 2) //mrgen general
        
    },

    text:{
        marginLeft: 155,
        marginTop: 30
    },
    text2:{
        marginLeft: 128,
        marginTop: 25
    },
    text3:{
        textAlign : 'center',
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(6)
    }


}))
function visible(visible){
    let value = ''
    if(visible === 1){
        value = 'SI'
    }
    else{
        if(visible === 0){
            value = "NO"
        }
    }
    return value
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#000443',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function ProjectTable() {

    const theme = useTheme();
    //let isRedirected = false;
    const [isRedirected, setIsRedirected] = useState(false);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
    let [data, setData] = useState([]);
    const history = useNavigate();
    const classes = useStyles()

    const [filterValue, setFilterValue] = useState('')
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(8);
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };


    
    // Espera a que la página se cargue completamente

    const filteredData = data.filter((project) => 
        project.num_proyecto.includes(filterValue)
    )

    const visibleData = filteredData.slice(startIndex, endIndex);

    const handleFilterChange = (event) => {
        setFilterValue(event.target.value);
      };

    const getProjects = async()=>{
        await axios.get('https://desamd-api.onrender.com/api/proyectos/getproyectos')
        .then(response=>{
            setData(response.data)
            console.log(response.status)
        }).catch(console.error())
    }

    const checkCookie = () => {
        const redirectedCookie = document.cookie.includes('isRedirected=true');
        return redirectedCookie;
    };
    useEffect(() => {
        // Verifica si hay una cookie para indicar que ya se redirigió
        const isRedirected = Cookies.get('isRedirected');
        
        if (!isRedirected) {
          // Redirige automáticamente al usuario a la ruta de autenticación
          const newWindow = window.open('https://desamd-api.onrender.com/api/auth/google', '_blank');
          if (newWindow) {
            // Marca la nueva ventana abierta
            newWindow.opener = null;
            // Establece una cookie para indicar que ya se redirigió
            Cookies.set('isRedirected', 'true');
          }
        }
    }, []);
  
    useEffect (() =>{

       
        getProjects();

    },[])

 
   /* useEffect(() => {
        // Verifica si ya se ha redirigido
        //let isRedirected = false;
        if (!isRedirected) {
            // Redirige automáticamente al usuario a la ruta de autenticación
            window.open('http://localhost:4000/api/auth/google', '_blank'); // Reemplaza con la URL correcta
            //isRedirected = true; // Marca como redirigido
            setIsRedirected(true)
        }
    }, [isRedirected]);*/

    
    

    const handleProjectDetails = (projectId) => {
        // Cambia la ruta a la página de detalles del proyecto
        history(`/proyectos/getproyectos/${projectId}`);
    };
    return (
        
        <div>
            <br></br>
            <Container maxWidth = 'xl'>
                <NavBarAdm/>
            </Container>
            <Container sx = {{marginTop:8, marginBottom:4}}  maxWidth = 'lg'>
                
                <h1 className = { classes.text3 }>
                    <Typography variant="h3" align="center" sx = {{fontWeight: 'bold' ,fontFamily: 'Open Sans, sans-serif'}} mb={6}>
                        Listado de Proyectos
                    </Typography>   
                </h1>
                <Box
                    sx={{
                        width: 400,
                        maxWidth: '100%',
                    }}
                >
                    <TextField
                        value = {filterValue}
                        onChange={handleFilterChange}
                        variant='outlined'
                        margin='dense'
                        placeholder = 'Filtar por numero de proyecto'
                        sx={{ fontFamily: 'Open Sans, sans-serif' }}
                        fullWidth
                        InputProps={{
                            startAdornment:(
                                <InputAdornment position='start'>
                                    <SearchIcon/>
                                </InputAdornment>
                            )
                        }}
                    />
                </Box>
                <br></br>
                <TableContainer component={Paper} >
                    <Table size="small" >
                        <TableHead>
                        <TableRow>
                            <StyledTableCell align='left' sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}>Nombre del Proyecto</StyledTableCell>
                            <StyledTableCell align='center' sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}>Codigo Proyecto</StyledTableCell>
                            <StyledTableCell align='center' sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}>ID Cliente</StyledTableCell>
                            <StyledTableCell align='center' sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}>Status</StyledTableCell>
                            <StyledTableCell align='center' sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}>Visible</StyledTableCell>
                            <StyledTableCell align='center' sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}>Acciones</StyledTableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {visibleData.map((project) => (
                                <StyledTableRow key={project.id_proyecto}>
                                    <StyledTableCell align='left' sx={{ fontFamily: 'Open Sans, sans-serif' }}>{project.titulo_proyecto}</StyledTableCell>
                                    <StyledTableCell align='center' sx={{ fontFamily: 'Open Sans, sans-serif' }}>{project.id_proyecto}</StyledTableCell>
                                    <StyledTableCell align='center' sx={{ fontFamily: 'Open Sans, sans-serif' }}>{project.id_cliente}</StyledTableCell>
                                    <StyledTableCell align='center' sx={{ fontFamily: 'Open Sans, sans-serif' }}>{project.status}</StyledTableCell>
                                    <StyledTableCell align='center' sx={{ fontFamily: 'Open Sans, sans-serif' }}>{visible(project.visible)}</StyledTableCell>
                                    <StyledTableCell align='center' sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                                        {/* Agrega un botón o enlace para ver detalles del proyecto */}
                                        <Button onClick={() => handleProjectDetails(project.id_proyecto)}>Ver detalles</Button>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
      
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[8, 10, 25, 50, 100]}
                    component="div"
                    count={filteredData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />                
                <Box
                    marginTop={5}
                    marginLeft={'30%'}
                    marginRight={'30%'}
                    
                >
                    <Button 
                        className={classes.button}
                        type = "button"
                        marginTop = {10}
                        size="large"
                        fullWidth
                        variant="contained"
                        href='/proyectos/agregarproyecto'
                        startIcon = {<AddIcon/>}
                        sx={{ fontFamily: 'Open Sans, sans-serif' }}
                    >
                        <Typography
                        >
                            Agregar Proyecto
                        </Typography>
                    </Button>
                </Box>

            </Container>
        </div>
    );
}

