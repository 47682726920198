import React, { useState, useEffect } from 'react';
import { Grid, makeStyles } from "@material-ui/core";
import NavBar from "../../components/NavBar";
import { Container, Typography,TextField, Button, CircularProgress } from "@mui/material";
import image1 from '../../assets/contactpage/sodachiloe.jpg'
import divider from '../../assets/divider_01.png' 
import axios from 'axios';
import swal from 'sweetalert'
import { LoadingButton } from "@mui/lab";
const useStyles = makeStyles(theme=>({
    root:{
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100v'

        
    },
    container:{
        opacity: '0.8',
        height: '80%', //largo del contenedor
        marginTop: theme.spacing(10), //altura del contenedor
        [theme.breakpoints.down(400 + theme.spacing(4)+4)]:{ //responsive
            marginTop: 0,
            width: '100%',
            height: '100%'
        }
    },
    div:{
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'

    },
    avatar:{
        margin: theme.spacing(6),
        marginBottom: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
       
    },
    form:{
        width: '100%',
        marginTop: theme.spacing(1)
    },
    button:{
        margin: theme.spacing(3, 0, 2) //mrgen general
        
    },

    text:{
        textAlign : 'center',
        marginTop: theme.spacing(15),
        
    },
    text2:{
        marginLeft: 128,
        marginTop: 25
    },
    text3:{
        textAlign : 'center',
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(6)
    },
    modal:{
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2,4,3),
        top: '50%',
        left:'50%',
        transform: 'translate(-50%, -50%)'
    },
    icons: {
        cursor: 'pointer'
    },
    inputMaterial:{
        width: '100%'
    },


}))

export default function Contacto(){
    const classes = useStyles()
    const [nombre, setNombre] = useState('');
    const [email, setEmail] = useState('');
    const [mensaje, setMensaje] = useState('');
    const [loading, setLoading] = useState(false);

    const handleNombreChange = (event) => {
        setNombre(event.target.value);
    };
    
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };
    
    const handleMensajeChange = (event) => {
        setMensaje(event.target.value);
    };
    
    const mostrarAlertaCorreoEnviado = () =>{
        swal({
            title:'Correo Enviado Correctamente!',
            icon:'success'
        })
    }

    const mostrarServerError = () => {
        swal({
            title: 'SERVER ERROR',
            icon: 'error'
        })
    }

    const areRequiredFieldsFilled = () => {
        const requiredFields = ["nombre","mail","mensaje"];
        


        if(!nombre || !email || !mensaje){
            return false
        }
        
        return true; // Si todos los campos requeridos están llenos, devuelve true
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        
        try {
            setLoading(true)

            // Enviar los datos al servidor
            const response = await axios.post('https://desamd-api.onrender.com/api/mailer/sendmail', {
            nombre,
            email,
            mensaje,
            });
            setLoading(false)
            // Manejar la respuesta del servidor (puede ser un mensaje de éxito, error, etc.)
            console.log('Respuesta del servidor:', response.data);
            if(response.status === 200){
                mostrarAlertaCorreoEnviado()
                setTimeout(() =>  {window.location.reload(false)}, 2000);
            }
            else{
                mostrarServerError()

                setTimeout(() =>  {window.location.reload(false)}, 2000);
            }   
        
            // Puedes mostrar un mensaje de éxito o redirigir al usuario a una página de confirmación aquí
        } catch (error) {
            // Manejar errores de la solicitud (por ejemplo, problemas de red, errores del servidor, etc.)
            console.error('Error al enviar los datos:', error);
        }
    };
    return(
        <div>
            <Container sx = {{marginTop:8}} maxWidth = 'xl'>
                <NavBar/>
                <h1 className = { classes.text }>
                    <Typography variant="h4" align="center" sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}} mb={6} >
                        Hagamos algo extraordinario trabajando juntos
                    </Typography>   
                </h1>
                <div align='center'>
                    <img src={image1}  style={{ width: '1000px', height: '580px' }} />
                </div>
                <br></br>
                <div align = 'center' >
                    <img src = {divider} />
                </div>
                <Typography variant="h4" align="center" sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}mt={6} mb={4}>Contacto</Typography>
                <br></br>
                <br></br>
                <Grid container>
                    <Grid item xs={12} md={6} style={{ marginTop:'35px' }}>  
                        <Typography variant="body1" className = { classes.text3 } mb = {8} mr={4} ml={4} align="justify" paragraph = {true} sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                            Cuéntanos acerca de tu proyecto o iniciativa. Estamos disponibles para analizar tus necesidades y preguntas, y así asegurar una implementación exitosa o continuar con tus campañas de Evaluación de Energía Eólica y Solar.
                            <br></br>
                            <br></br>
                            Nuestro objetivo es brindarte una solución personalizada que cumpla con tus requerimientos y cumpla con los estándares internacionales de calidad técnica.
                        </Typography>
                        <Typography variant="body1" className = { classes.text3 } mr={4} ml={4} align="justify" paragraph = {true} sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                            Dirección: Ruta D-43. Parc 1. N° 3910. Pan de Azucár.
                        </Typography>
                        <Typography variant="body1" className = { classes.text3 } mr={4} ml={4} align="justify" paragraph = {true} sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                            Coquimbo - CHILE
                        </Typography>
                        <Typography variant="body1" className = { classes.text3 }  mr={4} ml={4} align="justify" paragraph = {true} sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                            Telefono: +56 998011939
                        </Typography>
                        <Typography variant="body1" className = { classes.text3 }  mr={4} ml={4} align="justify" paragraph = {true} sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                            Horario: 9:00 am – 18:00 pm
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}> 
                        <form onSubmit={handleSubmit}>
                            <TextField
                                name='nombre'
                                label="Nombre"
                                fullWidth
                                variant="standard"
                                value={nombre}
                                onChange={handleNombreChange}
                                margin="normal"
                            />
                            <TextField
                                name='mail'
                                label="Email"
                                fullWidth
                                variant="standard"
                                value={email}
                                onChange={handleEmailChange}
                                margin="normal"
                            />
                            <TextField
                                name='mensaje'
                                label="Mensaje"
                                fullWidth
                                variant="standard"
                                multiline
                                rows={5}
                                value={mensaje}
                                onChange={handleMensajeChange}
                                margin="normal"
                            />
                            <br></br>
                            <br></br>
                            <LoadingButton disabled={!areRequiredFieldsFilled()} type="submit" variant="contained" color="primary" fullWidth>
                                {loading?<CircularProgress size={24}/>:"Enviar"}                                
                            </LoadingButton>
                        </form>
                    </Grid>
                </Grid>
                <br></br>
                <br></br>
                <div align = 'center' >
                    <img src = {divider} />
                </div>
                <br></br>
                <br></br>
            </Container>
            
        </div>
    );
}